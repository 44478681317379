
import { Component, Vue, Watch } from 'vue-property-decorator'
import api from '@/utilities/api'
import { starRatings, hotelFeatureOptions } from './helpers'
import RatesCard from './RateCard.vue'
import RatesEditor from './RatesEditor.vue'

@Component({
  components: {
    'rate-card': RatesCard,
    'rate-card-editor': RatesEditor,
  },
})
export default class HotelRates extends Vue {
  // data // ***
  expanded: Record<string, any>[] = []
  loading = false
  currency = 'GBP'
  rates: Record<string, any>[] = []
  requests: Record<string, any>[] = []
  rateInEdit: Record<string, any> = {}
  isEditing = false
  isSaving = false

  // computed // ***
  get supplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get suppliers(): Record<string, any> {
    return this.$store.getters['suppliers/suppliers']
  }
  get hasChildOrgs(): boolean {
    return this.suppliers.length > 1
  }
  get supplierId() {
    return this.supplier?.id
  }
  get parentSupplierId() {
    return this.$store.getters['core/organisation'].id
  }
  get hasRates() {
    return this.rates.length > 0
  }
  get hasOpenRateRequest() {
    return this.requests.length > 0
  }
  get urls() {
    return {
      rates: `organisation/GetHotelRateCards/${this.supplierId}`,
      requests: `organisation/GetOpenHotelRateRequest/${this.supplierId}`,
      childRates: `organisation/GetHotelRateCardsForSupplierChildOrgs/${this.parentSupplierId}`,
      childRequests: `organisation/GetOpenHotelRateRequestForSupplierChildOrgs/${this.parentSupplierId}`,
    }
  }
  get childOrganisations() {
    if (!this.suppliers || this.suppliers.length <= 1) return []
    if (this.rates.length === 0) return []
    const key = 'organisationId'
    var groupedRates = this.rates.reduce((acc, currentValue) => {
      let groupKey = currentValue[key]
      if (!acc[groupKey]) {
        acc[groupKey] = []
      }
      acc[groupKey].push(currentValue)
      return acc
    }, {})
    return this.suppliers.map((x: Record<string, any>) => {
      const requests = this.requests.filter((y: Record<string, any>) => y.organisationId === x.id)
      x.rateRequest = requests.length > 0 ? requests[0] : null
      if (!groupedRates[x.id]) {
        x.hotelRateCards = []
        return x
      }
      x.hotelRateCards = groupedRates[x.id]
      return x
    })
  }
  get headers() {
    return [{ text: '', value: 'name' }]
  }
  get starRatings() {
    return starRatings
  }
  get hotelFeatureOptions() {
    return hotelFeatureOptions(this)
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    if (this.supplierId) this.fetch()
  }

  // methods // ***
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('hotelRates')) this.$router.push('/errors/404')
  }
  async fetch() {
    this.loading = true
    await this.fetchRates()
    await this.fetchRequests()
    this.loading = false
  }
  async fetchRates() {
    const { urls } = this
    const url = this.hasChildOrgs ? urls.childRates : urls.rates
    const { data } = (await api(url, undefined, undefined)) || {}
    if (data) {
      if (data.rates) {
        this.rates = data.rates
      } else {
        this.rates = data
      }
      this.currency = data.currency
    } else {
      this.rates = []
    }
  }
  async fetchRequests() {
    const { urls } = this
    const url = this.hasChildOrgs ? urls.childRequests : urls.requests
    const { data } = (await api(url, undefined, undefined)) || {}
    if (data) {
      this.requests = data
    } else {
      this.requests = []
    }
  }
  addNewChildRate(request: Record<string, any>) {
    let draft = request.draftHotelProfile
    draft.rateRequestId = request.id
    this.rateInEdit = request.draftHotelProfile
    this.isEditing = true
  }
  addNew() {
    let draft = this.requests[0].draftHotelProfile
    draft.rateRequestId = this.requests[0].id
    this.rateInEdit = draft
    this.isEditing = true
  }
  async saveRateCard(rateCardUpdate: any) {
    let payload = { ...rateCardUpdate }

    api(
      `organisation/setHotelProfileAndRates/${payload.organisationId}`,
      { method: 'POST' },
      { data: payload }
    ).then(() => {
      this.isEditing = false
      this.fetch()
    })
  }
}
