
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { starRatings, hotelFeatureOptions } from './helpers'

const roomRates = {
  weekdayRate: 0,
  weekdayDayRate: 0,
  weekendRate: 0,
  weekendDayRate: 0,
}

@Component
export default class RatesEditor extends Vue {
  singleRoomRates = this.getEmptyRoomRate(3)
  twinDoubleRoomRates = this.getEmptyRoomRate(1)
  familyRoomRates = this.getEmptyRoomRate(2)
  tripleRoomRates = this.getEmptyRoomRate(132)
  continentalBreakfast = 0
  fullBreakfast = 0
  lunch = 0
  dinner = 0
  validFrom: DateTime = DateTime.now()
  validTo: DateTime = DateTime.now().plus({ years: 1 })
  submittedBy = null
  submittedEmail = null
  commission = { roomCommissionRate: 0, foodCommissionRate: 0, dynamicRate: 0 }
  termsConditions = false

  @Prop()
  currentRate!: Record<string, any>
  @Prop({ required: true, type: Boolean })
  isEditing!: boolean
  @Prop({ required: true, type: Boolean })
  isSaving!: boolean

  // Computed
  get requiredRule() {
    return [(v: any[]) => v.length > 0 || 'required']
  }
  get dateMask() {
    return 'dd/mm/yyyy'
  }
  get rateCard() {
    const rateCard: Record<string, any> = { ...this.currentRate, ...this.commission }
    rateCard.entries = [
      this.singleRoomRates,
      this.twinDoubleRoomRates,
      this.familyRoomRates,
      this.tripleRoomRates,
    ]
    rateCard.continentalBreakfast = this.continentalBreakfast
    rateCard.fullBreakfast = this.fullBreakfast
    rateCard.lunch = this.lunch
    rateCard.dinner = this.dinner
    rateCard.validFrom = this.validFrom
    rateCard.validTo = this.validTo
    return rateCard
  }
  get starRatings() {
    return starRatings
  }
  get hotelFeatureOptions() {
    return hotelFeatureOptions(this)
  }
  // watch // ***
  @Watch('isEditing')
  onValueChanged(e: boolean) {
    if (e) this.setInitialValues()
    else this.reset()
  }

  // mounted //

  setInitialValues() {
    if (this.currentRate) {
      this.singleRoomRates = this.getEntryByRequirementId(3) || this.getEmptyRoomRate(3)
      this.twinDoubleRoomRates = this.getEntryByRequirementId(1) || this.getEmptyRoomRate(1)
      this.familyRoomRates = this.getEntryByRequirementId(2) || this.getEmptyRoomRate(2)
      this.tripleRoomRates = this.getEntryByRequirementId(132) || this.getEmptyRoomRate(132)
      this.continentalBreakfast = this.getFoodPrice(13)
      this.fullBreakfast = this.getFoodPrice(12)
      this.lunch = this.getFoodPrice(15)
      this.dinner = this.getFoodPrice(14)
      this.validFrom = DateTime.fromISO(this.currentRate.validFrom)
      this.validTo = DateTime.fromISO(this.currentRate.validTo)
      this.commission = (({ roomCommissionRate, foodCommissionRate, dynamicRate }) => ({
        roomCommissionRate,
        foodCommissionRate,
        dynamicRate,
      }))(this.currentRate)
      const refs: Record<string, any> = this.$refs
      refs.form?.resetValidation()
    }
  }
  getEntryByRequirementId(requirementId: number) {
    if (!this.currentRate.organisationHotelRateCardEntries) return null
    return this.currentRate.organisationHotelRateCardEntries.find(
      (x: any) => x.hotelRequirementTypeId === requirementId
    )
  }
  getFoodPrice(requirementId: number): number {
    if (!this.currentRate.organisationHotelRateCardEntries) return 0
    const { weekdayRate = 0 } =
      this.currentRate.organisationHotelRateCardEntries.find(
        (x: any) => x.hotelRequirementTypeId === requirementId
      ) || 0
    return weekdayRate
  }

  async onSubmit() {
    const valid = await this.validateForm()
    if (!valid) return
    else this.$emit('submit', this.rateCard)
  }

  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }

  onCancel() {
    this.close()
  }
  close() {
    this.$emit('close', false)
  }
  reset() {
    this.singleRoomRates = this.getEmptyRoomRate(3)
    this.twinDoubleRoomRates = this.getEmptyRoomRate(1)
    this.familyRoomRates = this.getEmptyRoomRate(2)
    this.tripleRoomRates = this.getEmptyRoomRate(132)
    this.continentalBreakfast = 0
    this.fullBreakfast = 0
    this.lunch = 0
    this.dinner = 0
    this.validFrom = DateTime.now()
    this.validTo = DateTime.now().plus({ years: 1 })
    this.submittedBy = null
    this.submittedEmail = null
  }
  getEmptyRoomRate(rateTypeId: number) {
    const rate = { ...roomRates, hotelRequirementTypeId: rateTypeId }
    return rate
  }
}
