import { disableSorting } from '@/utilities/org'
import { title } from '@/utilities/filters'

export const starRatings = [
  { text: 'Unknown', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
]
export const hotelFeatureOptions = (context: Record<string, any>): Record<string, any>[] => {
  return [
    { text: String(context.$t('cmac.hotelRates.profileUnkown')), value: 0 },
    { text: String(context.$t('cmac.hotelRates.profileYes')), value: 1 },
    { text: String(context.$t('cmac.hotelRates.profileNo')), value: 2 },
    { text: String(context.$t('cmac.hotelRates.profileYesFree')), value: 3 },
  ]
}

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return disableSorting([
    { text: title(String(context.$t('cmac.hotelRates.ratesType'))), value: 'description' },
    {
      text: title(String(context.$t('cmac.hotelRates.ratesWeekdayRate'))),
      value: 'weekdayRate',
      align: 'center',
    },
    {
      text: title(String(context.$t('cmac.hotelRates.ratesWeekdayDayRate'))),
      value: 'weekdayDayRate',
      align: 'center',
    },
    {
      text: title(String(context.$t('cmac.hotelRates.ratesWeekendRate'))),
      value: 'weekendRate',
      align: 'center',
    },
    {
      text: title(String(context.$t('cmac.hotelRates.ratesWeekendDayRate'))),
      value: 'weekendDayRate',
      align: 'center',
    },
  ])
}
