
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import RatesEditor from './RatesEditor.vue'
import { hotelFeatureOptions, headers } from './helpers'

@Component({
  components: {
    'rates-editor': RatesEditor,
  },
})
export default class RatesCard extends Vue {
  @Prop({ required: true, type: Object })
  rateCard!: Record<string, any>

  @Prop({ type: String, default: 'GBP' })
  currency!: string

  get roomRates() {
    return this.rateCard.entries
      .filter((x: any) => x.hotelRequirementTypeId < 4 || x.hotelRequirementTypeId == 132)
      .sort((a: any, b: any) => a.maxOccupancy - b.maxOccupancy)
  }
  get headers() {
    return headers(this)
  }
  get featureOptions() {
    return hotelFeatureOptions(this)
  }
  get canEdit() {
    const validTo = DateTime.fromISO(this.rateCard.validTo)
    return validTo > DateTime.now()
  }
  get continentalBreakfastPrice() {
    const rate = this.rateCard.entries.find((x: any) => x.hotelRequirementTypeId === 13)
    if (rate) {
      return rate.weekdayRate
    } else {
      return 0
    }
  }
  get fullBreakfastPrice() {
    const rate = this.rateCard.entries.find((x: any) => x.hotelRequirementTypeId === 12)
    return rate?.weekdayRate || '-'
  }
  get lunchPrice() {
    const rate = this.rateCard.entries.find((x: any) => x.hotelRequirementTypeId === 15)
    return rate?.weekdayRate || '-'
  }
  get dinnerPrice() {
    const rate = this.rateCard.entries.find((x: any) => x.hotelRequirementTypeId === 14)
    return rate?.weekdayRate || '-'
  }
  get profile() {
    return this.rateCard.attributes
  }

  priceWithCurrency(price: string | number) {
    if (price === '-') {
      price = 0
    }
    if (typeof price === 'string') {
      price = parseFloat(price)
    }

    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: this.currency }).format(
      price
    )
  }

  editRate() {
    this.$emit('edit')
  }
  getHotelFeatureOptionText(value: number) {
    if (!value) return '-'
    return this.featureOptions.find(x => x.value === value)?.text || '-'
  }
  getDateString(date: string) {
    return DateTime.fromISO(date).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' })
  }
}
